<template>
  <b-form-group>
    <b-row>
      <b-col sm="6">
        <b-form-input class="col" :label="label" v-model="color" :disabled="isTransparent"></b-form-input>
      </b-col>
      <b-col sm="2">
        <b-form-checkbox v-model="isTransparent">透明</b-form-checkbox>
      </b-col>
      <b-col sm="1">
        <i class="color-button fa fa-paint-brush" v-show="normalizedColor == ''" :id="`color-button-${hash}`"></i>
        <div :id="`color-demo-${hash}`" class="color-demo" :style="{'background-color': color}" v-show="normalizedColor != ''">
        </div>
      </b-col>
    </b-row>
    <b-popover :target="`color-button-${hash}`" triggers="click blur" >
      <swatches-picker :value="color" @input="updateColor"></swatches-picker>
    </b-popover>
    <b-popover :target="`color-demo-${hash}`" triggers="click blur">
      <swatches-picker :value="color" @input="updateColor"></swatches-picker>
    </b-popover>
  </b-form-group>
</template>

<script>
import { Swatches } from 'vue-color'
export default {
  watch: {
    isTransparent: function (checked) {
      if (checked) {
        this.color = null;
      } else {
        this.color = '';
      }
    },
    color: function (value) {
      this.updateColor({hex: value})
    },
  },
  components: {
    SwatchesPicker: Swatches
  },
  created(){
    document.getElementsByTagName('body')[0].addEventListener('click', () => {
      this.$root.$emit('bv::hide::popover')
    });
  },
  computed: {
    normalizedColor() {
      return /^#[0-9A-F]{6}[0-9a-f]{0,2}$/i.test(this.color) ? this.color : ''
    }
  },
  props: {
    value: {
      default: '',
    },
    label: {
      default: '',
    }
  },
  data() {
    return {
      color: '',
      hash: '',
      isTransparent: false,
    }
  },
  mounted () {
    this.hash = Math.random().toString(36).slice(2);
    this.isTransparent = this.value == null;
    this.color = this.value
  },
  methods: {
    updateColor(color) {
      this.color = color.hex
      if (this.isTransparent) {
        this.color = null
      }
      this.$emit('input', this.color)
    }
  }
}
</script>

<style scoped>
.color-demo {
  cursor: pointer;
  height: 32px;
  width: 32px;
  border: solid 1px;
  border-radius: 3px;
  display: inline-blox;
}

.color-button {
  cursor: pointer;
}
</style>
<style>
.popover {
  max-width: 100%;;
}
</style>
